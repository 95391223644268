import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
//import { ReactComponent as Hamburger } from '../../assets/icons/hamburger.svg'
import { ReactComponent as Brand } from '../assets/logo.svg'
import BrandImage from '../assets/logo3.png';
import MenuIcon from '@material-ui/icons/Menu';
import RegisterButton from './RegisterButton';
import './NavBar2.css'



const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Define the mobile breakpoint

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setIsMobile(window.innerWidth <= 768); // Update isMobile when the window resizes
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  

  return (
    <nav className="navbar">
      <div className="container">
        <div className="logo">
        <img src={BrandImage} alt="Logo" />
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <MenuIcon />
        </div>
        <div className={`nav-elements  ${showNavbar && 'active'}`}>
          <ul>
          <li>
              <NavLink to="/login">Ingresar</NavLink>
            </li>
            <li>
              <NavLink to="/register">Registrarse</NavLink>
            </li>
            
            <li>
              <NavLink to="/about">Conócenos</NavLink>
            </li>
            <li>
              <NavLink to="/contact">Contacto</NavLink>
            </li>
          </ul>
        
          {isMobile && <RegisterButton  customStyles={{
            top: '10px',
      marginLeft: '25px',
      width: '80%',
      // Add any other styles you want to apply
    }}/>}
        </div>
       
      </div>

    </nav>
  )
}

export default Navbar