import { Image } from "./atoms/Image.tsx"
import bgImage from "./assets/HeroVector.png"
import heroImage from "./assets/hero-img.png"
import { Text } from "./atoms/Text.tsx"
import { HeroTexts } from "./particles/DataLists.tsx"
//import { Button2 } from "./atoms/Button.tsx"
//import { Play } from "phosphor-react";
import { Fade, Slide } from "react-awesome-reveal"

import { makeStyles } from '@material-ui/styles';
import {Button} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

// style constant
const useStyles = makeStyles((theme) => ({
    redButton: {
        fontSize: '1rem',
        fontWeight: 500,
        backgroundColor: theme.palette.secondary.main,
        
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: theme.palette.secondary.light[800]
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.875rem'
        }
    },
    signDivider: {
        flexGrow: 1
    },
    signText: {
        cursor: 'unset',
        margin: theme.spacing(2),
        padding: '5px 56px',
        borderColor: theme.palette.grey[100] + ' !important',
        color: theme.palette.grey[900] + '!important',
        fontWeight: 500
    },
    loginIcon: {
        marginRight: '16px',
        [theme.breakpoints.down('sm')]: {
            marginRight: '8px'
        }
    },
    loginInput: {
        ...theme.typography.customInput
    }
}));
function RegisterButton() {
    const classes = useStyles();
    const history = useHistory();
  
    const handleRegisterClick = () => {
      // Navigate to the "/register" route
      history.push('/register');
    }; 
    
    return (
        <Button
          className={classes.redButton}
          color="secondary"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={handleRegisterClick}
        >
          Registrarse
        </Button>
      );
    }



const Header = () => {
    const classes = useStyles();
  return (
    <section className="w-full lg:h-screen md:h-[550px] h-[830px] relative overflow-x-hidden flex justify-end">
         <svg
    className="h-[60%] w-[80%] lg:h-[90vh] md:h-[50vh] lg:w-1/2 md:w-[55%]"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 100 100" // Adjust the viewBox to fit your SVG dimensions
  >
    <use xlinkHref="./assets/hero-img.png#vectorId" />
  </svg>
        <Image className="h-[60%] w-[80%] lg:h-[90vh] md:h-[50vh] lg:w-1/2 md:w-[55%]" image={bgImage} alt="Hero Background Vector" />
        <main className="w-full lg:h-full h-auto grid md:grid-cols-2 absolute top-0 left-0 lg:px-24 md:px-8 px-5 pt-10 md:pt-0 lg:pt-10">
            <div className="flex flex-col justify-center md:gap-6 gap-3 md:order-1 order-2">
                <Text as="p" className="text-color2 uppercase pt-10 tracking-widest lg:text-base text-purple text-sm font-normal ">
                    <Fade>Controlar tus finanzas nunca habia sido  tan fácil !</Fade>
                 
                </Text>
                <Text as="h1" className=" text-color3 lg:text-7xl md:text-5xl text-3xl font-medium">
                    <Fade>{HeroTexts.secondText}</Fade>
                </Text>
                <Text as="p" className="text-color3 md:text-base text-sm text-justify text-purple font-light">
                    <Fade>{HeroTexts.thirdText}</Fade>
                </Text>
                <div className="justify-between items-center lg:gap-12 md:gap-0 gap-0">
                                <RegisterButton>Registrarse</RegisterButton>
                                    
                             
                    <div className="flex items-center lg:gap-6 gap-3 cursor-pointer">
                        <Text as="span" className="relative flex h-14 w-14">
                            <Text as="span" className="animate-ping absolute inline-flex h-full w-full rounded-full bg-color1 opacity-75"></Text>
                            <Text as="span" className="relative flex justify-center items-center text-white rounded-full h-14 w-14 bg-color1">
                            
                            </Text>
                        </Text>
                
                               
                    </div>

                </div>
            </div>
            <div className="flex flex-col items-center justify-start md:order-2 order-1">
                <Slide direction="right">
                    <Image image={heroImage} alt="Hero Image" className="" />
                </Slide>

            </div>
        </main>
    </section>
)
}
  
  export default Header;