import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom'; // Import useHistory if needed
import { Divider } from '@material-ui/core';
// style constant
const useStyles = makeStyles((theme) => ({

  divider: {
    height: '1px', // Set the height of the divider
    width: '80%', // Make the divider span the entire width
    backgroundColor: 'gray', // Customize the divider color
    margin: '0 25px', // Add margin for spacing
  },
    redButton: {
        fontSize: '1rem',
        fontWeight: 500,
        backgroundColor: theme.palette.secondary.main,
        
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: theme.palette.secondary.light[800]
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.875rem'
        }
    },}));
function RegisterButton({ customStyles }) {
  const classes = useStyles();
  const history = useHistory();

  const handleRegisterClick = () => {
    // Navigate to the "/register" route
    history.push('/register');
  }; 

  return (
    <>
    <Divider className={classes.divider} /> 
    <Button
      className={classes.redButton}
      color="secondary"
    
      style={customStyles} 
      type="submit"
      variant="contained"
      onClick={handleRegisterClick}
    >
      Registrarse Gratis Ahora !
    </Button></>
  );
}

export default RegisterButton;
