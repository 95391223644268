import { useEffect, useState } from "react";
import moneydb from "./moneydb";
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux';

export const useMoneydb = () =>{
     const authToken = useSelector((state) => state.account.token);
     const dispatch = useDispatch();
     const [isLoading, setIsLoading]=useState(true)
     const [incomes, setincomes]=useState(0)
     const [spendings, setspendings]=useState(0)
     const [savings, setsavings]=useState(0)
     const [ValidToken, setValidToken]=useState(false)
   

     moneydb.defaults.headers.common['Authorization'] = authToken;
     moneydb.defaults.headers.common['Content-Type'] = 'application/json';
   
  
      
     const getLogin = async () => {
      try {
        const responseToken = await moneydb.post('users/total_income_amount');
    
        if (responseToken.status === 200) {
          setValidToken(true);
        }
      } catch (error) {
        // Handle errors here
        console.error('An error occurred while fetching the token:', error);
    
        // You can set validToken to false or handle the error in a way that suits your application.
        dispatch({ type: 'LOGOUT' });
      }
    }

      const getIncome= async() => {
        const promise_total_income_amount=  moneydb.post('users/total_income_amount');
        const promise_total_spending_amount= moneydb.post('users/total_spending_amount');
        const promise_total_savings_amount= moneydb.post('users/total_saving_amount');



        const responses = await Promise.all([promise_total_income_amount,  promise_total_spending_amount, promise_total_savings_amount])
    
        setincomes(responses[0].data)
        setspendings(responses[1].data)
        setsavings(responses[2].data)
        setIsLoading (false)
                    } 
  
      

  useEffect(() => {
        
    getIncome();
    getLogin();


  }, []);
        
return {
    incomes,
    spendings,
    savings,
    isLoading,
    ValidToken
       }
}
