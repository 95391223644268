//import Navbar from './organs/NavBar';
import HeroSection from "./Header"
import Navbar from './organs/Navbar2';


const Home = () => {
    return (
        <>
        <Navbar/>
            <HeroSection />
       
        </>
    )
}

export default Home