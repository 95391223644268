import PropTypes from 'prop-types';
import React, { Component, Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useMoneydb } from '../../api/useMoneydb';
// project imports
import config from '../../config';
import { checkboxClasses } from '@material-ui/core';


//-----------------------|| GUEST GUARD ||-----------------------//

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */
const GuestGuard = ({ children }) => {
    const account = useSelector((state) => state.account);
    const token = useSelector((state) => state.token);
    const { isLoggedIn } = account;

   
   
    if (isLoggedIn) {
        return <Redirect to={config.defaultPath} />;
    }
   


    return children;
};

GuestGuard.propTypes = {
    children: PropTypes.node
};

export default GuestGuard;
